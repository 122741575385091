<template>
  <div id="dialogSettingRawMaterial">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  v-model="item.ProductId"
                  label="รหัส"
                  dense
                  required
                  readonly
                  ref="ProductId"
                  @keydown.enter="NextFocus('ProductName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.ProductName"
                  label="ชื่อ"
                  dense
                  required
                  ref="ProductName"
                  @keydown.enter="NextFocus('ProductBarcode')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.ProductBarcode"
                  label="บาร์โค้ด"
                  dense
                  required
                  ref="ProductBarcode"
                  @keydown.enter="NextFocus('UnitId')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- <v-text-field
                  v-model="item.Unit"
                  label="หน่วย"
                  dense
                  required
                  ref="Unit"
                  @keydown.enter="NextFocus('Price')"
                ></v-text-field> -->
                <v-autocomplete
                  v-model="item.UnitId"
                  :items="unitList"
                  item-value="UnitId"
                  item-text="UnitName"
                  :return-object="false"
                  dense
                  label="หน่วย"
                  ref="UnitId"
                  @keydown.enter="NextFocus('Price')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.number="item.Price"
                  label="ราคา"
                  type="number"
                  dense
                  required
                  ref="Price"
                  @keydown.enter="NextFocus('Status')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="isEdit">
                <v-autocomplete
                  v-model="item.Status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="Status"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" v-if="isEdit">
                <v-switch
                  class="pl-6 mt-1"
                  v-model="item.Vat"
                  inset
                  dense
                  color="primary"
                  :label="item.Vat == true ? 'ภาษี' : 'ไม่มีภาษี'"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";

export default {
  name: "DialogSettingRawMaterial",
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
      unitList: [],
    };
  },
  mounted() {
    this.getUnitList();
  },
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "actProductCreate",
      "actProductUpdate",
      "actUnitGets",
    ]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
    },
    getUnitList() {
      this.actUnitGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.unitList = data.result;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.result);
        }
      });
    },
    submit() {
      this.itemEdit.CreatedBy = this.user.UserId;
      this.itemEdit.ModifiedBy = this.user.UserId;

      if (this.itemEdit.Price == "") {
        this.itemEdit.Price = 0;
      }
      if (this.itemEdit.Vat) {
        this.itemEdit.Vat = 1;
      } else {
        this.itemEdit.Vat = 0;
      }

      if (this.indexEdit == -1) {
        //เพิ่ม
        this.actProductCreate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      } else {
        //แก้ไข
        console.log(this.item);
        this.actProductUpdate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      }
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style></style>
